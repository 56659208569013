export const ENDPOINTS = {
  AUTH: {
    loginRoute: "auth/login",
    refreshTokenRoute: "/Account/refreshtoken",
    changePassword: "Account/ChangePassword",
    resetPassword: "Account/ResetPassword",
    authenticate: "/Account/token",
    getTFARegContent: "/Account/TwoFARegistration",
    getTFARegSelection: "/Account/TwoFARegistrationSelection/",
    sendTwaFAVerificationCode: "/Account/TwoFASendCode",
    getTFAVerificationContent: "/Account/GetVerificationCode/?",
    validateTFACode: "/Account/TwoFAValidate",
    getTwoFASettings: "/Account/TwoFASettings",
    disableTwoFA: "/Account/DisableTwoFA",
    getssotoken: "/account/getssotoken"
  },
  ACCOUNT: {
    getClientId: "Account/ClientID"
  },
  LOGGER: {},
  BENEFITS: {
    getWellness: "/Wellness",
    updateWellness: "/Wellness",
    getShowFlex: "/EnrollmentHeader/showFlex",
    deleteEnrollment: "/EnrollmentFooter",
    nextEnrollmentStep: "/EnrollmentFooter/nextenrollmentstep",
    previousEnrollmentStep: "/EnrollmentFooter/previousenrollmentstep",
    progressIndicator: "/EnrollmentFooter/progressIndicator",
    changeOption: "/ChangeOption",
    saveBenefit: "/SaveBenefitChanges",
    coverageWaiver: "/CoverageWaiver",
    optOutWaiver: "/OptOutWaiver",
    removeWaiver: "/RemoveWaiver",
    removeEOIOption: "/RemoveEOI?benefitID=",
    dependentCoverageChange: "/DependentCoverageChange",
    getSmokerContent: "/Content/GetSmokerContent",
    confirmEnrollment: "/EnrollmentFooter",
    saveSmokerBenefit: "/EnrollmentFooter/SaveBenefits"
  },
  FLEX: {
    saveFlex: "/Flex/SaveFlex",
    getFlex: "/Flex?GroupID=",
    updateFlex: "/Flex/UpdateFlex",
    reallocateFlex: "/Flex/ReallocateFlex"
  },
  CONTENTAPIS: {
    getloginContent: "Content/login",
    getforgotPasswordContent: "Account/ForgotPassword",
    gettemporaryPasswordContent: "Account/TemporaryPassword",
    getFooterContent: "Content/Footer",
    getHeaderImages: "Content/headerImages",
    getWelcomeContent: "Home/Welcome",
    putWelcomeMessages: "Home/WelcomeMessages",
    getMainMenu: "Menu/main",
    getSecondMenu: "Menu/mainstep2",
    getPopupURL: "Content/",
    getClientStyles: "Content/ClientStyles",
    getCurrentCoverage: `ReviewCoverage/CurrentCoverage`,
    getCoverageList: `ReviewCoverage/PreviousCoverageList`,
    getPreviousCoverage: `ReviewCoverage/PreviousCoverage`,
    getfinishEnrollment: `FinishedEnrollment`,
    getfinishEnrollmentForm: `FinishedEnrollment/Forms`,
    getpdfStatement: `/ReviewCoverage/PDFStatement?`,
    getLifeChangeEvent: `/Home/LifeChangeEvent`,
    getContentResource: `/content/contentResource`,
    getFileUploadInfoContent: "FileUpload/FileUploadInfo",
    getUploadedFilesInfoContent: "FileUpload/UploadedFilesInfo",
    uploadFile: "FileUpload/UploadFile/",
    downloadUploadDocuments: "/FileUpload/UploadedFile/?fileID="
  },
  PROFILEAPIS: {
    getProfile: `/Profile?isReview=`,
    updateProfile: `/Profile`,
    getupdateDependents: `/Dependent`,
    getSummaryPersonal: "Summary/Personal",
    getSummaryDependents: "Summary/Dependents"
  },
  BENEFICIARYAPIS: {
    updateBeneficiary: `/Beneficiary?benefitID=`,
    addEditEstate: `/Beneficiary/AddEditEstate`,
    addEditPerson: `/Beneficiary/AddEditPerson`,
    addEditCharity: "/Beneficiary/AddEditCharity",
    getBeneficiariesAll: `/Beneficiary/All`,
    getBeneficiaries: `/Beneficiary?isReview=false`,
    deleteBeneficiary: `/Beneficiary/RemoveBeneficiary?beneficiaryID=`,
    saveESignature: `Summary/SaveESignature`,
    reallocateMultiBenefit: `/Beneficiary/Reallocate?benefitID=`,
    proceedToNextMultiBenefit: `/Beneficiary/ProceedToNext?benefitIDs=`,
    getSummaryBeneficiaries: `Summary/Beneficiaries`,
    getBenefitSummary: "Summary/BenefitSummary",
    getSummaryBenefits: "/Summary/Benefits",
    getSummaryFlex: "/Summary/Flex"
  },
  DIRECTBILLING: {
    getDirectBilling: "/DirectBilling",
    saveDirectBilling: "/DirectBilling/save",
    deleteDirectBilling: "/DirectBilling/Delete"
  }
};
