import { isMobileOnly, isMobile } from "react-device-detect";
import { USER_TOKEN } from "../utils";

export const Life = {
  name: "LIFE",
  link: "life/11",
  display: "",
  order: -1
};
export const Health = {
  name: "HEALTH",
  link: "health/1",
  display: "",
  order: -1
};
export const Welcome = {
  name: "WELCOME",
  link: "",
  display: "",
  order: -1
};
export const Dependents = {
  name: "REVIEWDEPENDENTPROFILE",
  link: "Dependent",
  display: "",
  order: 0
};
export const Wellness = {
  name: "WELLNESS",
  link: "wellness",
  display: "Wellness",
  order: -1
};
export const Reviewpersonalprofile = {
  name: "PERSONALINFORMATION",
  link: "Profile",
  order: 0,
  display: "Review Personal Profile"
};
export const LifeChangeEvent = {
  name: "LifeChangeEvent",
  link: "LifeChangeEvent",
  order: 40,
  display: "Life Change Event"
};

export const deviceType = isMobileOnly ? "Mobile" : "Desktop";
export const isMobileDevice = isMobile;
export const navigateInitialState = { state: { reload: false, lang: USER_TOKEN.get()?.lang } };
export const navigateChangedState = { state: { reload: true, lang: USER_TOKEN.get()?.lang } };
