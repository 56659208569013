export const AuthenticationConstants = {
  ALREADY_CACHED: "AUTH__ALREADY_CACHED",

  LOADING: "AUTH__LOADING",

  LOGGING_IN: "AUTH__LOGGING_IN",
  SSO_LOGIN: "AUTH__SSO_LOGIN",
  SSO_LOGIN__FAILED: "AUTH__SSO_LOGIN__FAILED",

  REFRESH_TOKEN: "AUTH__REFRESH_TOKEN",
  REFRESH_TOKEN__SUCCESS: "AUTH__REFRESH_TOKEN__SUCCESS",
  REFRESH_TOKEN__FAILED: "AUTH__REFRESH_TOKEN__FAILED",
  CHANGE_USER_PASSWORD: "AUTH__CHANGE_USER_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  AUTH_LOGIN: "AUTH_LOGIN",
  SIGN_IN: "AUTH__SIGN_IN",
  SIGN_OUT: "AUTH__SIGN_OUT",
  NEED_TFA: "NEED_TFA",
  PASSWORD_CHANGED: "PASSWORD_CHANGED",
  CHANGE_LOCALE: "CHANGE_LOCALE",
  UPLOAD_EmployeeFILE_INFO: "UPLOAD_EmployeeFILE_INFO",
  GET_TWOFA_REG_CONTENT: "GET_TWOFA_REG_CONTENT",
  GET_REGISTRATION_FORM: "GET_REGISTRATION_FORM",
  SEND_VERIFICATION_CODE: "SEND_VERIFICATION_CODE",
  GET_VERIFICATION_CONTENT: "GET_VERIFICATION_CONTENT",
  GET_TFA_SETTINGS: "GET_TFA_SETTINGS",
  TFA_DISABLE: "TFA_DISABLE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR"
};
export const ContentDataConstants = {
  GET_CLIENT_DETAILS_CONTENT: "GET_CLIENT_DETAILS_CONTENT",
  GET_CLIENT_NAME: "GET_CLIENT_NAME",
  SHOW_WARNING_MESSAGE: "SHOW_WARNING_MESSAGE",
  GET_CLIENT_STYLES_CONTENT: "GET_CLIENT_STYLES_CONTENT",
  GET_LOGIN_CONTENT: "GET_LOGIN_CONTENT",
  GET_FOOTER_CONTENT: "GET_FOOTER_CONTENT",
  GET_CONTENT_FOOTER_PAGE: "GET_CONTENT_FOOTER_PAGE",
  GET_HEADER_IMAGES_CONTENT: "GET_HEADER_IMAGES_CONTENT",
  GET_FORGOT_PASSWORD_CONTENT: "GET_FORGOT_PASSWORD_CONTENT",
  GET_TEMPORARY_PASSWORD_CONTENT: "GET_TEMPORARY_PASSWORD_CONTENT",
  GET_HELP_CONTENT: "GET_HELP_CONTENT",
  GET_WELCOME_CONTENT: "GET_WELCOME_CONTENT",

  PUT_WELCOME_CONTENT: "PUT_WELCOME_CONTENT",
  PUT_WELCOME_MESSAGES: "PUT_WELCOME_MESSAGES",
  PUT_WELCOME_REQUESTED_OBJECT: "PUT_WELCOME_REQUESTED_OBJECT",

  GET_MAIN_MENU_CONTENT: "GET_MAIN_MENU_CONTENT",
  SET_MENU2_FLAG: "SET_MENU2_FLAG",
  GET_RESOURSE_CONTENT: "GET_RESOURSE_CONTENT",
  BENEFIT_URL_LIST: "BENEFIT_URL_LIST",
  GET_API_LOADER: "GET_API_LOADER",
  IS_DISABLED: "IS_DISABLED"
};

export const StatusConfigConstants = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  DISSMISS_MESSAGE: "DISSMISS_MESSAGE",
  IS_LOADER: "IS_LOADER"
};

export const CoverageConfigConstants = {
  REVIEW_CURRENT_COVERAGE: "REVIEW_CURRENT_COVERAGE",
  REVIEW_PAST_COVERAGE: "REVIEW_PAST_COVERAGE",
  REVIEW_PAST_COVERAGE_LIST: "REVIEW_PAST_COVERAGE_LIST",
  FINISH_ENROLLMENT: "FINISH_ENROLLMENT"
};

export const AppConfigConstants = {
  GET_APPCONFIG_PENDING: "GET_APPCONFIG_PENDING",
  GET_APPCONFIG_SUCCESS: "GET_APPCONFIG_SUCCESS",
  GET_APPCONFIG_ERROR: "GET_APPCONFIG_ERROR"
};

export const EventConfigConstants = {
  SET_PAGE_SETTING: "SET_PAGE_SETTING",
  SET_MINI_CART_INITIAL_STATE: "SET_MINI_CART_INITIAL_STATE",
  FOCUS_INITIALIZING: "FOCUS_INITIALIZING",
  GET_CHANGE_EVENT: "GET_CHANGE_EVENT",
  GET_LIFE_EVENT_CHANGE: "GET_LIFE_EVENT_CHANGE",
  GET_PROGRESS_INDICATOR: "GET_PROGRESS_INDICATOR",
  NEXT_ENROLLMENT_STEP: "NEXT_ENROLLMENT_STEP",
  GET_ENROLMENT_HEADER: "GET_ENROLMENT_HEADER",
  GET_ENROLMENT_FOOTER: "GET_ENROLMENT_FOOTER",
  DISABLE_NEXT: "DISABLE_NEXT",
  SET_NAV_ERROR: "SET_NAV_ERROR"
};

export const PageConfigConstants = {
  SET_BASE_PAGE_TITLE: "SET_BASE_PAGE_TITLE",
  SET_BASE_PAGE_ITEM: "SET_BASE_PAGE_ITEM",
  SET_HEADER_INFO: "SET_HEADER_INFO",
  SET_NAV_URL: "SET_NAV_URL",
  // SET STEP DSC
  SET_STEP_DESCRIPTION: "SET_STEP_DESCRIPTION"
};

export const ProfileConfigConstants = {
  GET_PROFILE_CONTENT: "GET_PROFILE_CONTENT",
  GET_DEPENDENTS: "GET_DEPENDENTS",
  UPDATE_PERSONAL_INFO: "UPDATE_PERSONAL_INFO",
  UPDATE_DEPENDENT: "UPDATE_DEPENDENT",
  GET_SUMARY_DEPENDENTS: "GET_SUMMARY_DEPENDENTS",
  GET_SUMMARY_PERSONAL: "GET_SUMMARY_PERSONAL"
};

export const FileUploadConfigConstants = {
  FILE_UPLOAD_INFO: "FILE_UPLOAD_INFO",
  UPLOADED_FILE: "UPLOADED_FILE",
  UPLOADED_FILES_INFO: "UPLOADED_FILES_INFO",
  UPLOAD_FILE: "UPLOAD_FILE",
  PROGRESS_PERCENTAGE: "PROGRESS_PERCENTAGE"
};

export const BenefitConstants = {
  GET_WELLNESS: "GET_WELLNESS",
  UPDATE_WELLNESS: "UPDATE WELLNESS",
  GET_SHOW_FLEX: "GET_SHOW_FLEX",
  BENEFIT_URL_LIST: "BENEFIT_URL_LIST",
  GET_MULTI_BENEFIT_DATA: "GET_MULTI_BENEFIT_DATA",//use for desktop view
  GET_SINGLE_BENEFIT_DATA: "GET_SINGLE_BENEFIT_DATA",//use for mobile view
  SAVE_WAIVER_FORM: "SAVE_WAIVER_FORM",
  REMOVE_WAIVER: "REMOVE_WAIVER",
  GET_SMOKER_CONTENT: "GET_SMOKER_CONTENT",
  REMOVE_EOI: "REMOVE_EOI",
  PUT_DEPENDED_COVERAGE: "PUT_DEPENDED_COVERAGE",
  GET_BENEFIT_SUMMARY: "GET_BENEFIT_SUMMARY",
  SET_BENEFIT_OPTION: "SET_BENEFIT_OPTION",
  CHANGE_OPTION: "CHANGE_OPTION",
  SET_FOCUS_ON_BENEFIT_ERROR: "SET_FOCUS_ON_BENEFIT_ERROR",
  GET_SUMMARY_BENEFITS: "GET_SUMMARY_BENEFITS",
  GET_SUMMARY_FLEX: "GET_SUMMARY_FLEX"
};

//Flex Dollar
export const FlexConstants = {
  GET_FLEX: "GET_FLEX",
  SAVE_FLEX: "SAVE_FLEX",
  UPDATE_FLEX: "UPDATE_FLEX",
  RELOCATE_FLEX: "RELOCATE_FLEX"
};

export const BeneficiaryConstants = {
  GET_BENEFICIARIES_ALL: "GET_BENEFICIARIES_ALL",
  GET_BENEFICIARIES: "GET_BENEFICIARIES",
  CREATE_BENEFICIARY: "CREATE_BENEFICIARY",
  UPDATE_BENEFICIARY: "UPDATE_BENEFICIARY",
  UPDATE_INDIVIDUAL_BENEFICIARY: "UPDATE_INDIVIDUAL_BENEFICIARY",
  FINISH_ENROLLMENT_FORM: "FINISH_ENROLLMENT_FORM",
  GET_SUMMARY_BENEFICIARIES: "GET_SUMMARY_BENEFICIARIES",
  SAVE_ESIGNATURE: "SAVE_ESIGNATURE",
  REALLOCATE_MULTIBENEFIT: "REALLOCATE_MULTIBENEFIT",
  PROCEED_TO_NEXT: "PROCEED_TO_NEXT",
  SET_FLEX_BENEFICIARY_BENEFIT_ID: "SET_FLEX_BENEFICIARY_BENEFIT_ID",
  DELETE_BENEFICIARY: "DELETE_BENEFICIARY",
   SET_MULTI_BENEFICIARIES_LIST:"SET_MULTI_BENEFICIARIES_LIST"
};

export const DirectBillingConstants = {
  GET_DIRECTBILLING: "GET_DIRECTBILLING",
  SAVE_DIRECTBILLING: "SAVE_DIRECTBILLING",
  DELETE_DIRECTBILLING: "DELETE_DIRECTBILLING"
};
