export const contentResource = {
  contents: {
    Welcome: [
      {
        keyName: "homeBanner",
        display: "Testing is testing",
        textToRead: "Testing",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblCompleteEnrollmentAlert",
        display:
          'Note: You have a completed enrolment. If you need to perform a "Life Event", please choose "Cancel Enrolment", then complete the "Life Event", and then complete the enrolment process again.',
        textToRead:
          'Note: You have a completed enrolment. If you need to perform a "Life Event", please choose "Cancel Enrolment", then complete the "Life Event", and then complete the enrolment process again.',
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblConfirmCancelDialogTitle",
        display: "Confirm Cancel Enrolment",
        textToRead: "Confirm Cancel Enrolment",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblConfirmModifyDialogTitle",
        display: "Confirm Modify Enrolment",
        textToRead: "Confirm Modify Enrolment",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblDisclaimerTitle",
        display: "Disclaimer",
        textToRead: "Disclaimer",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblEnrollmentClosed",
        display: "The enrolment period is now closed",
        textToRead: "The enrolment period is now closed",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblEnrollmentComplete",
        display: "Enrolment complete form",
        textToRead: "Enrolment complete form",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblEnrollmentInstructions",
        display:
          "Throughout the enrolment if you select &#147;Logoff&#148; or &#147;Sign Out&#147;, your data will be saved and you can continue your enrolment at a later time (as long as the enrolment window is open). Once you select &#147;Confirm&#148;, your selectio",
        textToRead:
          "Throughout the enrolment if you select &#147;Logoff&#148; or &#147;Sign Out&#147;, your data will be saved and you can continue your enrolment at a later time (as long as the enrolment window is open). Once you select &#147;Confirm&#148;, your selectio",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblEnrollmentOptions",
        display: "Enrolment Options",
        textToRead: "Enrolment Options",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblEnrollmentWaiting",
        display: "No benefits are effective for this plan year",
        textToRead: "No benefits are effective for this plan year",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblEnrollmentWindow",
        display: "Your enrollment window is from [!STARTDATE] to [!ENDDATE]. Your selections will be effective on the dates that will be presented to you during your online enrollment.",
        textToRead: "Your enrollment window is from [!STARTDATE] to [!ENDDATE]. Your selections will be effective on the dates that will be presented to you during your online enrollment.",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblHomeBanner",
        display: "Welcome to  WEBS_Telus ON WEBSINCDEV \r\nBenefits",
        textToRead: "Welcome to  WEBS_Telus ON WEBSINCDEV \r\nBenefits",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventEffectiveDateLabel",
        display: "Date of [!LIFE EVENT NAME]:",
        textToRead: "Date of [!LIFE EVENT NAME]:",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventIntroText1",
        display:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred after [!PLAN START DATE].  If your event occurred prior to this date, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to change your coverage.  If your event type is no",
        textToRead:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred after [!PLAN START DATE].  If your event occurred prior to this date, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to change your coverage.  If your event type is no",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventIntroText2",
        display:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred within the last [!LIFE EVENT PERIOD] days. If your event occurred earlier than that, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to change your coverage. If your ev",
        textToRead:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred within the last [!LIFE EVENT PERIOD] days. If your event occurred earlier than that, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to change your coverage. If your ev",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventIntroText3",
        display:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred after [!LAST EVENT DATE].  If your event occurred prior to this date, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to change your coverage. If your event type is not",
        textToRead:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred after [!LAST EVENT DATE].  If your event occurred prior to this date, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to change your coverage. If your event type is not",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventIntroText4",
        display: "A <b>[!LIFE EVENT NAME]</b> has already been entered today. If you need to make other changes, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to complete those changes.",
        textToRead:
          "A <b>[!LIFE EVENT NAME]</b> has already been entered today. If you need to make other changes, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to complete those changes.",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventIntroText5",
        display:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred on or after your coverage start date of [!COVERAGE START DATE]. If your event occurred earlier than that, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to complete th",
        textToRead:
          "A <b>[!LIFE EVENT NAME]</b> may only be entered on this site if it occurred on or after your coverage start date of [!COVERAGE START DATE]. If your event occurred earlier than that, contact [!BENEFIT CENTER NAME] at [!BENEFIT CENTER NUMBER] to complete th",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblPageTitle",
        display: "Home",
        textToRead: "Home",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblPartialEnrollmentAlert",
        display:
          "Note: You have a partially completed enrolment.  If you need to perform a Life Event Change, you will have to cancel and recomplete your enrolment once you have completed your Life Event Change .",
        textToRead:
          "Note: You have a partially completed enrolment.  If you need to perform a Life Event Change, you will have to cancel and recomplete your enrolment once you have completed your Life Event Change .",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lifeEventDateError",
        display: "The date entered is invalid",
        textToRead: "The date entered is invalid",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkCancelEnrollment",
        display: "Cancel Enrolment",
        textToRead: "Cancel Enrolment",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkContinueEnrollment",
        display: "Continue Enrolment",
        textToRead: "Continue Enrolment",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkLess",
        display: "Less..",
        textToRead: "Less..",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkModifyEnrollment",
        display: "Modify Enrolment",
        textToRead: "Modify Enrolment",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkMore",
        display: "More...",
        textToRead: "More...",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkReviewEnrollment",
        display: "I want to Review my Current Coverage",
        textToRead: "I want to Review my Current Coverage",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkStartAnnualEnrollment",
        display: "I want to enroll",
        textToRead: "I want to enroll",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkStartLifeEvent",
        display: "Life Event Change",
        textToRead: "Life Event Change",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkStartNewHireEnrollment",
        display: "I want to enroll",
        textToRead: "I want to enroll",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "btnNext",
        display: "Next",
        textToRead: "Next",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "btnCancel",
        display: "Cancel",
        textToRead: "",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "btnSave",
        display: "Save",
        textToRead: "",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblIAgree",
        display: "I Agree",
        textToRead: "I Agree",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblIDisagree",
        display: "I do not Agree",
        textToRead: "I do not Agree",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblYes",
        display: "Yes",
        textToRead: "Yes",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblNo",
        display: "No",
        textToRead: "No",
        numOfPopupLinkPresent: 0
      }
    ],
    LifeChangeEvent: [
      {
        keyName: "lblDatePlaceHolder",
        display: "MM/DD/YYYY",
        textToRead: "MM/DD/YYYY",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblLifeEventEffectiveDateLabel",
        display: "Date of Life Event Change:",
        textToRead: "Date of Life Event Change:",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblPageTitle",
        display: "Home",
        textToRead: "Home",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lifeEventDateError",
        display: "The date entered is invalid",
        textToRead: "The date entered is invalid",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lnkStartLifeEvent",
        display: "Life Event Change",
        textToRead: "Life Event Change",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "LifeEventDisclaimer",
        display:
          "Your employer may require you to provide documentation regarding the date of your status change.<br><br>Benefits plans must be administered in accordance with plan rules. &nbsp;Under plan rules, you are permitted to change your coverage during the year only if you experience certain life events as described in the Plan Details, such as birth of a child or marriage.<br><br>By completing this change, you certify that you have read the Life Event information in the Plan Details and that the information that you are about to provide is true and correct. &nbsp;You understand that any fraudulent statement, falsification or material omission of information may subject you to discipline up to and including termination of employment.",
        textToRead:
          "Your employer may require you to provide documentation regarding the date of your status change.<br><br>Benefits plans must be administered in accordance with plan rules. &nbsp;Under plan rules, you are permitted to change your coverage during the year only if you experience certain life events as described in the Plan Details, such as birth of a child or marriage.<br><br>By completing this change, you certify that you have read the Life Event information in the Plan Details and that the information that you are about to provide is true and correct. &nbsp;You understand that any fraudulent statement, falsification or material omission of information may subject you to discipline up to and including termination of employment.",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "ErrorMustSelectLifeEvent",
        display: "You must select a type of change before you can proceed",
        textToRead: "You must select a type of change before you can proceed",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "btnNext",
        display: "Next",
        textToRead: "Next",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "btnCancel",
        display: "Cancel",
        textToRead: "",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "btnSave",
        display: "Save",
        textToRead: "",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblIAgree",
        display: "I Agree",
        textToRead: "I Agree",
        numOfPopupLinkPresent: 0
      },
      {
        keyName: "lblIDisagree",
        display: "I do not Agree",
        textToRead: "I do not Agree",
        numOfPopupLinkPresent: 0
      }
    ],
    CoverageHistory: [],
    ConfirmationStatement: [],
    ChangePassword: []
  },
  controlResource: {}
};
